<script lang="ts" setup>
import {useFavicon} from '@vueuse/core'
import AppLayout from '@/components/layout/AppLayout.vue'

const {company} = useCompany()
const siteName = computed<string>(() => company.value ? company.value.name : 'Cargando...')

if (company.value?.favicon) {
  useFavicon(company.value.favicon)
}

useHead({
  htmlAttrs: {
    lang: 'es',
  },
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }
  ],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value
  },
})
</script>

<template>
  <app-layout />
</template>
