<script lang="ts" setup>
import {navigateTo} from "#imports"
import AppLogo from './AppLogo.vue'

const {company} = useCompany()
</script>

<template>
  <div class="py-6 px-6 mx-0 mt-20 lg:mx-20">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-2">
        <app-logo class="h-[49px] mx-auto" />
      </div>

      <div class="col-span-12 md:col-span-10">
        <div class="grid grid-cols-12 gap-8 text-center md:text-left">
          <div class="col-span-12 md:col-span-4">
            <h4 class="font-medium text-2xl leading-normal mb-4 text-surface-900 dark:text-surface-0">{{ company?.name }}</h4>
            <a class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100">Acerca de</a>
            <a class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100">Contacto</a>
          </div>

          <div class="col-span-12 md:col-span-4">
            <h4 class="font-medium text-2xl leading-normal mb-4 text-surface-900 dark:text-surface-0">Comunidad</h4>
            <a
              v-if="company?.url_facebook"
              class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100"
              @click="navigateTo(company.url_facebook, {
                external: true,
                open: {
                  target: '_blank',
                },
              })"
            >
              <i class="mr-1 pi pi-facebook" /> Facebook
            </a>
            <a
              v-if="company?.url_facebook"
              class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100"
              @click="navigateTo(company.url_instagram, {
                external: true,
                open: {
                  target: '_blank',
                }
              })"
            >
              <i class="mr-1 pi pi-instagram" /> Instagram
            </a>
            <a
              v-if="company?.url_tiktok"
              class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100"
              @click="navigateTo(company.url_tiktok, {
                external: true,
                open: {
                  target: '_blank',
                }
              })"
            >
              <i class="mr-1 pi pi-tiktok" />TikTok
            </a>
          </div>

          <div class="col-span-12 md:col-span-4">
            <h4 class="font-medium text-2xl leading-normal mb-4 text-surface-900 dark:text-surface-0" >Legal</h4>
            <a class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100" @click="navigateTo({name: 'docs-terms-of-use'})">Términos de uso</a>
            <a class="leading-normal text-xl block cursor-pointer mb-2 text-surface-700 dark:text-surface-100" @click="navigateTo({name: 'docs-privacy-policy'})">Política de Privacidad</a>
            <a class="leading-normal text-xl block cursor-pointer text-surface-700 dark:text-surface-100" @click="navigateTo({name: 'docs-cookie-policy'})">Política de cookies</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
