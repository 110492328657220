<script lang="ts" setup>
import {navigateTo} from '#imports'
import AppLogo from './AppLogo.vue'
import AccountMenu from '@/components/menus/AccountMenu.vue'

const {account} = useAuth()
</script>

<template>
  <div class="py-6 px-6 mx-0 md:mx-12 lg:mx-20 lg:px-20 flex items-center justify-between relative lg:static">
    <app-logo class="cursor-pointer h-[42px]" @click="navigateTo({name: 'index'})" />

    <Button
      class="lg:!hidden"
      text
      severity="secondary"
      rounded
      v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
    >
      <i class="pi pi-bars !text-2xl"></i>
    </Button>
    <div class="items-center bg-surface-0 dark:bg-surface-900 grow justify-between hidden lg:flex absolute lg:static w-full left-0 top-full px-12 lg:px-0 z-20 rounded-border">
      <ul class="list-none p-0 m-0 flex lg:items-center select-none flex-col lg:flex-row cursor-pointer gap-8">
        <li>
          <a @click="navigateTo({name: 'index'})" class="px-0 py-4 text-surface-900 dark:text-surface-0 font-medium text-xl">
            <span>Inicio</span>
          </a>
        </li>

      </ul>
      <div v-if="account">
        <AccountMenu />
      </div>
      <div v-else class="flex border-t lg:border-t-0 border-surface py-4 lg:py-0 mt-4 lg:mt-0 gap-2">
        <Button label="Iniciar sesión" link rounded @click="navigateTo({name: 'account-login'})" />
        <Button label="Registrarse" rounded @click="navigateTo({name: 'account-register'})" />
      </div>
    </div>
  </div>
</template>
